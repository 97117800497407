import React from 'react'
import { Helmet } from 'react-helmet'

import { withPrefix } from 'gatsby'

import logo from '../images/logo.png'
import mainSlide from '../images/main-slide-img2.png'
import cloudsLight from '../images/clouds-light.png'

import '../styles/bootstrap.min.css'
import '../styles/font-awesome.min.css'
import '../styles/slick.css'
import '../styles/style.css'

const IndexPage = () => {
  return (
    <div>
      <Helmet>
        {/* <title>Taylor's Tidy Trees</title>
      <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" /> */}
        <meta charset="UTF-8"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1,maximum-scale=1, user-scalable=no"
        ></meta>
        <link
          href="data:image/x-icon;base64,AAABAAEAEBAQAAEABAAoAQAAFgAAACgAAAAQAAAAIAAAAAEABAAAAAAAgAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAA/4QAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABEREREREQAAEAAAAAABAAAQAAAAAAEAABAAEAEAAQAAEAEAABABAAAQEAAAAQEAABABAAAQAQAAEAAQAQABAAAQAAAAAAEAABAAAAAAAQAAEAAAERERAAAQAAAQARAAABAAABARAAAAEAAAERAAAAAQAAARAAAAABERERAAAADAAwAA3/sAAN/7AADduwAA29sAANfrAADb2wAA3bsAAN/7AADf+wAA3wMAAN9nAADfTwAA3x8AAN8/AADAfwAA"
          rel="icon"
          type="image/x-icon"
        />
        <title>RIP</title>
        {/*
        import "../js/jquery.min.js"
        import "../js/bootstrap.min.js"
        import "../js/slick.min.js"
        import "../js/paper-full.min.js"
        import "../js/main.min.js"
        import "../js/metaball.js"
      */}
      </Helmet>
      <header></header>
      <main className="body">
        <nav id="nav" className="navbar navbar-default navbar-full">
          <div className="container container-nav">
            <div className="navbar-header">
              <img className="logo" src={logo} alt="FORM.RIP" />
            </div>
            <div
              style={{ height: '1px' }}
              role="main"
              aria-expanded="false"
              className="navbar-collapse collapse"
              id="bs"
            >
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <a
                    className="chat-button"
                    href="https://www.mercadopago.com/mla/debits/new?preapproval_plan_id=e4e35a1626ff4ebf8bf856a493842945"
                  >
                    BUY NOW
                  </a>
                </li>
                <li>
                  <a className="chat-button" href="/signin/">
                    INGRESAR
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div id="top-content" className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div id="main-slider">
                  <div className="slide info-slide2" title="Get started">
                    <div className="image-holder">
                      <img src={mainSlide} alt="" />
                    </div>
                    <div className="text-holder txt animated">
                      Simple, Fast and Functional HTML forms
                      <br />
                      Out off any code.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* fondo verde */}
        <div id="info" className="container-fluid">
          <canvas id="infobg" data-paper-resize="true"></canvas>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="info-tt">As easy as this...</div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="info-text">
                  <div align="center">
                    <p className="card-code">
                      &lt;form action="https://api.form.rip/send-email" method="POST"&gt;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="
                      <span className="attr">hidden</span>" name="
                      <span className="attr">key</span>" value="
                      <span className="attrkey">YOUR_FORM.RIP_KEY</span>"&gt;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="
                      <span className="attr">text</span>" name="
                      <span className="attr">message</span>"&gt;
                      <br />
                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="
                      <span className="attr">hidden</span>" name="
                      <span className="attr">fr_mail</span>" value="
                      <span className="attrkey">DEST_EMAIL</span>"&gt;
                      <br /> */}
                      &nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="
                      <span className="attr">email</span>" name="
                      <span className="attr">email</span>" value="
                      <span className="attrkey">REPLY_EMAIL</span>"&gt;
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&lt;input type="
                      <span className="attr">submit</span>" value="
                      <span className="attr">Send</span>"&gt;
                      <br />
                      &lt;/form&gt;
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="features" className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row-title">What is form.rip for?</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-6">
                <div className="mfeature-box">
                  <div className="mfeature-icon">
                    <div className="icon-bg">
                      <img src={cloudsLight} alt="" />
                    </div>
                    <i className="fa fa-globe"></i>
                  </div>
                  <div className="mfeature-title">Limited Static Sites!</div>
                  <div className="mfeature-details">
                    You can use form.rip in all static sites
                  </div>
                </div>
              </div>
              <div className="col-sm-4 col-md-6">
                <div className="mfeature-box">
                  <div className="mfeature-icon">
                    <div className="icon-bg">
                      <img src={cloudsLight} alt="" />
                    </div>
                    <i className="fa fa-line-chart"></i>
                  </div>
                  <div className="mfeature-title">
                    Simple but Powerfull forms!
                  </div>
                  <div className="mfeature-details">
                    You can set up extra attributes like, back url, to return to
                    your site, and make your site more dinamic.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="pricing" className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row-title">Our Licencies.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 col-md-4">
                <div className="pricing-box pr-color3">
                  <div className="pricing-title" title="starter">
                    Starter
                  </div>
                  <div className="pricing-box-body">
                    <div className="pricing-amount">
                      <div className="price">
                        <span className="currency">$</span>
                        <span className="amount">1</span>
                      </div>
                      <div className="duration">monthly</div>
                    </div>
                    <div className="pricing-details">
                      <ul>
                        <li>20 submissions / month</li>
                        <li className="not-supported">Extra Parameters</li>
                        <li className="not-supported">Attachment support</li>
                        <li className="not-supported">Ajax Functionality</li>
                        <li className="not-supported">Ajax Custom Fields</li>
                      </ul>
                    </div>
                    <div className="pricing-button">
                      <a
                        href="http://mpago.la/2C3fmJ"
                        className="pricing-button"
                      >
                        Buy now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="pricing-box pr-color1">
                  <div className="pricing-title" title="Standard">
                    Standard
                  </div>
                  <div className="pricing-box-body">
                    <div className="pricing-amount">
                      <div className="price">
                        <span className="currency">$</span>
                        <span className="amount">3</span>
                      </div>
                      <span className="duration">monthly</span>
                    </div>
                    <div className="pricing-details">
                      <ul>
                        <li>50 submissions / month</li>
                        <li>1 Extra Parameters</li>
                        <li>Attachment support</li>
                        <li className="not-supported">Ajax Functionality</li>
                        <li className="not-supported">Ajax Custom Fields</li>
                      </ul>
                    </div>
                    <div className="pricing-button">
                      <a
                        href="http://mpago.la/3H3CiG"
                        className="pricing-button"
                      >
                        Buy now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="pricing-box pr-color2 recommended">
                  <div className="pricing-title" title="Premium">
                    Premium
                  </div>
                  <div className="pricing-box-body">
                    <div className="pricing-amount">
                      <div className="price">
                        <span className="currency">$</span>
                        <span className="amount">5</span>
                      </div>
                      <span className="duration">monthly</span>
                    </div>
                    <div className="pricing-details">
                      <ul>
                        <li>Unlimited submissions</li>
                        <li>All Extra Parameters</li>
                        <li>Attachment support</li>
                        <li>Ajax Functionality</li>
                        <li>Ajax Custom Fields</li>
                      </ul>
                    </div>
                    <div className="pricing-button">
                      <a
                        href="https://www.mercadopago.com/mla/debits/new?preapproval_plan_id=e4e35a1626ff4ebf8bf856a493842945"
                        className="pricing-button"
                      >
                        Buy now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="apps" className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row-title" title="Extra Functionality">
                  You can send aditional parameters to explore extra
                  functionality.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="apps-holder">
                  <div className="apps-details-holder">
                    <div className="app-details">
                      <div className="app-details1 show-details">
                        <div className="app-title">subject_</div>
                        <div className="app-text">
                          <span style={{ color: '#6db56b' }}>
                            &lt;input type="<span className="attr">hidden</span>
                            " name="<span className="attr">subject_</span>"/&gt;
                          </span>
                          <br />
                          This value is used for the email's subject, so that
                          you can quickly reply to submissions without having to
                          edit the subject line each time.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apps-holder">
                  <div className="apps-details-holder">
                    <div className="app-details">
                      <div className="app-details1 show-details">
                        <div className="app-title">name_</div>
                        <div className="app-text">
                          <span style={{ color: '#6db56b' }}>
                            &lt;input type="<span className="attr">text</span>"
                            name="<span className="attr">name_</span>"/&gt;
                          </span>
                          <br />
                          This value is used for set the client / user's name,
                          and it'll be send inside email's body.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apps-holder">
                  <div className="apps-details-holder">
                    <div className="app-details">
                      <div className="app-details1 show-details">
                        <div className="app-title">cel_number_</div>
                        <div className="app-text">
                          <span style={{ color: '#6db56b' }}>
                            &lt;input type="<span className="attr">text</span>"
                            name="<span className="attr">cel_number_</span>
                            "/&gt;
                          </span>
                          <br />
                          This value is used for set the client / user's
                          cellphone number, and it'll be send inside email's
                          body.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apps-holder">
                  <div className="apps-details-holder">
                    <div className="app-details">
                      <div className="app-details1 show-details">
                        <div className="app-title">attachment_</div>
                        <div className="app-text">
                          <span style={{ color: '#6db56b' }}>
                            &lt;input type="<span className="attr">file</span>"
                            name="<span className="attr">attachment_[]</span>
                            "/&gt;
                          </span>
                          <br />
                          This value is used for the email's attachments, so
                          that you can select files and send it like email's
                          attachments.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apps-holder">
                  <div className="apps-details-holder">
                    <div className="app-details">
                      <div className="app-details1 show-details">
                        <div className="app-title">back_url</div>
                        <div className="app-text">
                          <span style={{ color: '#6db56b' }}>
                            &lt;input type="<span className="attr">hidden</span>
                            " name="<span className="attr">back_url</span>"
                            value="
                            <span className="attr">http://your_back_url</span>
                            "/&gt;
                          </span>
                          <br />
                          Provide an URL to go back when the email has been
                          sent. We return the property "form_rip_result" by post
                          whith values "SENT" or "ERROR".
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apps-holder">
                  <div className="apps-details-holder">
                    <div className="app-details">
                      <div className="app-details1 show-details">
                        <div className="app-title">v_card</div>
                        <div className="app-text">
                          <span style={{ color: '#6db56b' }}>
                            &lt;input type="<span className="attr">hidden</span>
                            " name="<span className="attr">v_card</span>"
                            value="
                            <span className="attr">
                              VCARD VERSION (3.0 or 2.1)
                            </span>
                            "/&gt;
                          </span>
                          <br />
                          Use this parameter if you want attach a VCARD contact
                          into the message.
                          <div style={{ color: '#bca253' }}>
                            WARNING: name_ and cel_number_ are required for use
                            v_card parameter.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="apps-holder">
                  {/* <div className="apps-details-holder">
                    <div className="app-details" style={{ height: '230px' }}>
                      <div className="app-details1 show-details">
                        <div className="app-title">Ajax Functionality</div>
                        <div className="app-text" style={{ textAlign: 'left' }}>
                          <span style={{ color: '#6db56b' }}>
                            {'$.ajax({'}
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;url:
                            "https://form.rip?ajax=1" ,<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;method: "POST" ,
                            <br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data:{'{'} key:
                            "<span className="attr">your_key</span>", message:"
                            <span className="attr">Your Message</span>",
                            fr_email: "
                            <span className="attr">Email to send</span>", email:
                            "<span className="attr">Email to reply</span>" {'}'}
                            ,<br />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <span className="attrkey">dataType: "json"</span>
                            <br />
                            {'});'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="apps-holder">
                    {/* <div className="apps-details-holder">
                      <div className="app-details" style={{ height: '250px' }}>
                        <div className="app-details1 show-details">
                          <div className="app-title">Ajax Custom Fields</div>
                          <div
                            className="app-text"
                            style={{ textAlign: 'left' }}
                          >
                            <span style={{ color: '#6db56b' }}>
                              {'$.ajax({'}
                              <br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;url:
                              "https://form.rip?ajax=1" ,<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;method: "POST"
                              ,<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data:{'{ '}
                              key: "<span className="attr">your_key</span>",
                              message:"
                              <span className="attr">Your Message</span>",
                              fr_email: "
                              <span className="attr">Email to send</span>",
                              email: "
                              <span className="attr">Email to reply</span>",
                              custom_fields : {'{ '}"
                              <span className="attr">field 1 name</span>" : "
                              <span className="attr">field_value</span>", "
                              <span className="attr">field 2 name</span>" : "
                              <span className="attr">field_value</span>" {'} }'}
                              ,<br />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              <span className="attrkey">dataType: "json"</span>
                              <br />
                              {'});'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="apps-holder">
                      <div className="apps-details-holder">
                        <div
                          className="app-details"
                          style={{ height: '440px' }}
                        >
                          <div className="app-details1 show-details">
                            <div className="app-title">
                              Ajax Full Functionality
                            </div>
                            <div
                              className="app-text"
                              style={{ textAlign: 'left' }}
                            >
                              <span style={{ color: '#6db56b' }}>
                                var data = new{' '}
                                <span className="attr">FormData()</span>;<br />
                                data.append(<span className="attr">"key"</span>,
                                "your_formrip_key");
                                <br />
                                data.append(
                                <span className="attr">"message"</span>,
                                "your_msg");
                                <br />
                                {/* data.append(
                                <span className="attr">"fr_email"</span>,
                                "destination_email");
                                <br /> */}
                                data.append(
                                <span className="attr">"email"</span>,
                                "reply_email");
                                <br />
                                <span style={{ color: '#899cbf' }}>
                                  {'// CUSTOM FIELDS //'}
                                </span>
                                <br />
                                data.append(
                                <span className="attr">"my_custom_field_1"</span>,
                                "my_custom_field_1");
                                <br />
                                data.append(
                                <span className="attr">"my_custom_field_2"</span>,
                                "my_custom_field_2");
                                {/* <br />
                                data.append(
                                <span className="attr">"custom_fields"</span>,
                                <span className="attrkey">JSON.stringify(</span>
                                {'{'}"field 1 name" : "field_value", "field 2
                                name" : "field_value"{'}'}
                                <span className="attrkey">)</span>); */}
                                <br />
                                <span style={{ color: '#899cbf' }}>
                                  {'// ATTACHMENTS //'}
                                </span>{' '}
                                <br />
                                data.append(
                                <span className="attr">'attachment_[]'</span>,
                                $('#file')[0].files[0]);
                                <br />
                                <br />
                                {'$.ajax({'}
                                <br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;url:
                                "https://api.form.rip/send-email" ,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;method:
                                "POST" ,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="attrkey">
                                  contentType: false
                                </span>{' '}
                                ,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <span className="attrkey">
                                  processData: false
                                </span>{' '}
                                ,<br />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data:{' '}
                                <span className="attr">data</span>
                                <br />
                                {'});'}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <script src={withPrefix('jquery.min.js')} type="text/javascript" />
      <script src={withPrefix('bootstrap.min.js')} type="text/javascript" />
      <script src={withPrefix('slick.min.js')} type="text/javascript" />
      <script src={withPrefix('paper-full.min.js')} type="text/javascript" />
      <script src={withPrefix('main.min.js')} type="text/javascript" />
      <script src={withPrefix('metaball.js')} type="text/javascript" />
    </div>
  )
}
export default IndexPage
